<template>
  <div class="lpzylist-style">
    <div class="outbox">
      <el-card class="box-card">
        <div class="box1">{{ $t("text.t323") }}</div>
        <van-tabs
          title-active-color="#323232"
          title-inactive-color="#909090"
          v-model="active"
          animated
          color="#323232"
          :line-width="80"
        >
          <van-tab :title="$t('text.t328')">
            <div>
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="$t('text.t39')"
                :offset="10"
                @load="getlist"
              >
                <div class="box2 between-center">
                  <div class="box2-item">{{ $t("text.t330") }}<br />(LP)</div>
                  <div class="box2-item">{{ $t("text.t326") }}<br />(OMT)</div>
                  <div class="box2-item">{{ $t("text.t299") }}</div>
                  <div class="box2-item">{{ $t("text.t327") }}<br />(OMT)</div>
                  <div class="box2-item">{{ $t("text.t118") }}</div>
                </div>
                <div
                  class="box3 between-center"
                  v-for="item in list"
                  :key="item.id"
                >
                  <div class="box3-item">{{ item.lp_num }}</div>
                  <div class="box3-item">{{ item.omt_num }}</div>
                  <div class="box3-item">{{ item.pledge_cycle_name }}</div>
                  <div class="box3-item">{{ item.omt_num_total }}</div>
                  <div class="box3-item">
                    {{ $parseTime(item.pledge_time) }}
                  </div>
                </div>
              </van-list>
            </div>
          </van-tab>
          <van-tab :title="$t('text.t329')">
            <div>
              <van-list
                v-model="loading1"
                :finished="finished1"
                :finished-text="$t('text.t39')"
                :offset="10"
                @load="getlist1"
              >
                <div class="box2 between-center">
                  <div class="box2-item">{{ $t("text.t330") }}<br />(LP)</div>
                  <div class="box2-item">{{ $t("text.t326") }}<br />(OMT)</div>
                  <div class="box2-item">{{ $t("text.t299") }}</div>
                  <div class="box2-item">{{ $t("text.t327") }}<br />(OMT)</div>
                  <div class="box2-item">{{ $t("text.t118") }}</div>
                </div>
                <div
                  class="box3 between-center"
                  v-for="item in list1"
                  :key="item.id"
                >
                  <div class="box3-item">{{ item.lp_num }}</div>
                  <div class="box3-item">{{ item.omt_num }}</div>
                  <div class="box3-item">{{ item.pledge_cycle_name }}</div>
                  <div class="box3-item">{{ item.omt_num_total }}</div>
                  <div class="box3-item">
                    {{ $parseTime(item.pledge_time) }}
                  </div>
                </div>
              </van-list>
            </div>
          </van-tab>
        </van-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      active: 0,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,

      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getlist() {
      const user_address = this.$local.get("shop:account");
      let arg = {
        page: this.page,
        limit: this.limit,
        address: user_address,
        status:1
      };
      this.$http.post("/omt_pledge/pledgeList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getlist1() {
      const user_address = this.$local.get('shop:account');
      let arg = {
        page: this.page1,
        limit: this.limit,
        address: user_address,
        status:2
      };
      this.$http.post("/omt_pledge/pledgeList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        this.list1 = [...this.list1, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.lpzylist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 10px;
        text-align: center;
      }
      .box2 {
        padding-top: 20px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 20%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 20%;
          word-break: break-all;
        }
      }
    }
  }
}
</style>